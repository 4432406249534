import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import airports from './airports'
import profile from './profile'
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "@/main.js"
 
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: true,
    done: false
  },
  mutations: {
    startLoading(s) {
      s.loading = true
    },
    endLoading(s) {
      s.loading = false
    },
    setDone(s) {
      s.done = true
    }
  },
  actions: {
    async fetchData({commit, dispatch}, user) {
      const q = query(collection(db, "companies"), where("owner", "==", user.uid));
      
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        if (doc) {
          commit('setDone')
        }
      });
    }
  },
  modules: {
    auth,
    airports,
    profile
    
  }
})
