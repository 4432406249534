<template>
  <div>
    <div class="text-h6 mb-5 teal--text">
      Entreprise
    </div>
    <div>
      <v-text-field
        color="teal"
        label="Nom"
        v-model="company.name"
      ></v-text-field>
      <v-text-field
        color="teal"
        label="Address"
        v-model="company.address"
      ></v-text-field>
      <v-row>
        <v-col>
          <v-text-field
            color="teal"
            label="Ville"
            v-model="company.city"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            color="teal"
            label="Code postal"
            v-model="company.postalCode"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        color="teal"
        label="Siret"
        v-model="company.siret"
      ></v-text-field>
      <v-text-field
        color="teal"
        label="Numéro de TVA"
        v-model="company.tva"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    company() {
      return this.$store.state.profile.company
    }
  }

}
</script>

<style>

</style>