import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { getAuth } from "firebase/auth"
import { Firestore } from 'firebase/firestore'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      appbar: false,
      auth: false,
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      appbar: false,
      auth: true
    }
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import('../views/Info.vue'),
    meta: {
      appbar: false,
      auth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiredAuth = to.matched.some(record => record.meta.auth)

  const auth = getAuth();
  const user = auth.currentUser; // null if no user

  if (requiredAuth && !user) {
    next('/')
  } else {
    next()
  }

})

export default router
