<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="teal"
          elevation="0"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Verifier et enregistrer
        </v-btn>
      </template>

      <v-card class="pa-10">
        <v-card
          outlined
          class="mb-5"
        >
          <v-card-text>
            Vous souhaitez livrer à
            <div class="text-h6 teal--text">
              {{airport.name}}
            </div>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="mb-5"
        >
          <v-card-text>
            <div class="text-h6 teal--text">{{company.name}}</div>
            <div class="text-caption">{{company.address}}</div>
            <div class="text-caption">{{company.city}} {{company.postalCode}}</div>
            <div>Siret <strong>{{company.siret}}</strong></div>
            <div>Numero TVA <strong>{{company.tva}}</strong></div>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="mb-5"
        >
          <v-card-text>
            <div class="text-h6 teal--text">{{restaurant.name}}</div>
            <div class="text-caption">{{restaurant.address}}</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <div
              class="d-flex"
              v-for="h in hours"
              :key="h"
            >
              {{week[h.day_of_week - 1]}}
              <div v-if="h.open" class="ml-1">{{h.hours[0]}} – {{h.hours[1]}}</div>
              <div v-else class="ml-1">Fermé</div>
            </div>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="mb-5"
        >
          <v-card-text>
            Contacter au cas besoin
            <div class="text-h6 teal--text">
              {{manager.name}} {{manager.surname}}
            </div>
            {{manager.phone}}
          </v-card-text>
        </v-card>
        <v-btn
          color="teal"
          elevation="0"
          dark
          @click="create"
        >Enregistrer</v-btn>
        <v-btn
          class="red lighten-1 ml-1"
          elevation="0"
          dark
          @click="dialog = false"
        >Modifier</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    week: ["Lundi", "Mardi", "Mercredi", "Jedui", "Vendredi", "Samedi", "Dimanche"]
  }),
  computed: {
    airport() {
      return this.$store.state.profile.airport || 'Not selected'
    },
    company() {
      return this.$store.state.profile.company
    },
    restaurant() {
      return this.$store.state.profile.restaurant
    },
    manager() {
      return this.$store.state.profile.manager
    },
    hours() {
      return this.$store.state.profile.days
    },
    auth() {
      return this.$store.state.auth.auth
    }
  },
  methods: {
    create() {
      const company = {
        ...this.company,
        owner: this.auth.uid,
        manager: this.manager
      }
      const restaurant = {
        ...this.restaurant,
        hours: this.hours,
        owner: this.auth.uid,
        airport: this.airport.id
      }
      const data = {
        company,
        restaurant,
      }
      this.$store.dispatch('createProfile', data)
      // this.$router.push('/info')
    }
  }
}
</script>

<style>

</style>