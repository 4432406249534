import { getAuth, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import router from "../router";

export default {
  state: {
    auth: null,
    error: null
  },
  mutations: {
    setError(s, payload) {
      s.error = payload
    },
    clearError(s) {
      s.error = null
    },
    setAuth(s, payload) {
      s.auth = payload
    },
    clearAuth(s) {
      s.auth = null
    }
  },
  actions: {
    async register({commit, dispatch}, data) {
      const auth = getAuth();

      await createUserWithEmailAndPassword(auth, data.email, data.password)
        .then((userCredential) => {
          const user = userCredential.user;
          commit('setAuth', user)
          commit('clearError')
        })
        .catch((error) => {
          const errorMessage = error.message;
          commit('setError', errorMessage)
        });
    },
    logout({commit, dispatch}) {
      const auth = getAuth();
      signOut(auth).then(() => {
        commit('clearAuth')
        commit('clearError')
        commit('clearAirports')
        commit('clearSelectedAirports')
        commit('clearAirport')
      }).catch((error) => {
        console.log(error)
      })
      router.push('/')
    }
  },
  modules: {
  },
}
