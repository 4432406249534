<template>
  <v-container
    fill-height
    class="text-center justify-center"
  >
    <div>
      <v-progress-circular
        width="3"
        size="50"
        color="teal"
        indeterminate
      ></v-progress-circular>
      <div class="mt-5">
        Loading...
      </div>
    </div>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>