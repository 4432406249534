<template>
  <v-container>
    auth yes
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({}),
}
</script>
