import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAz-IOPcKJ4DOHNp0TOsbV73fb5Qf_fx5Y",
  authDomain: "ici-delivery-1839f.firebaseapp.com",
  projectId: "ici-delivery-1839f",
  storageBucket: "ici-delivery-1839f.appspot.com",
  messagingSenderId: "458421198602",
  appId: "1:458421198602:web:251829453e11937a1ce4f3"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth()

onAuthStateChanged(auth, (user) => {
  if (user) {
    store.commit('setAuth', user)
    store.commit('endLoading')
    store.dispatch('fetchData', user)
  } else {
    console.log('auth not found')
    store.commit('endLoading')
  }
});

export const db = getFirestore(app);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
