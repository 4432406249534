import { collection, doc, setDoc } from "firebase/firestore"
import { db } from "@/main.js"

export default {
  state: {
    days: [
      {
        day_of_week: 1,
        open: true,
        hours: ["11:00", "19:00"]
      },
      {
        day_of_week: 2,
        open: true,
        hours: ["11:00", "19:00"]
      },
      {
        day_of_week: 3,
        open: true,
        hours: ["11:00", "19:00"]
      },
      {
        day_of_week: 4,
        open: true,
        hours: ["11:00", "19:00"]
      },
      {
        day_of_week: 5,
        open: true,
        hours: ["11:00", "19:00"]
      },
      {
        day_of_week: 6,
        open: true,
        hours: ["11:00", "19:00"]
      },
      {
        day_of_week: 7,
        open: true,
        hours: ["11:00", "19:00"]
      },
    ],
    airport: null,
    company: {
      name: null,
      address: null,
      city: null,
      postalCode: null,
      siret: null,
      tva: null,
    },
    restaurant: {
      name: null,
      address: null,
    },
    manager: {
      name: null,
      surname: null,
      phone: null,
    }
  },
  mutations: {
    setAirport(s, payload) {
      s.airport = payload
    },
    clearAirport(s) {
      s.airport = null
    }
  },
  actions: {
    async addCompany({dispatch}, data) {
      const ref = doc(collection(db, "companies"));
      await setDoc(ref, data);
    },
    async addRestaurant({dispatch}, data) {
      const ref = doc(collection(db, "restaurants"));
      await setDoc(ref, data);
    },
    async createProfile({commit, dispatch}, data) {
      await dispatch('addCompany', data.company)
      await dispatch('addRestaurant', data.restaurant)
      commit('setDone')
    }
  },
  modules: {
  }
}
