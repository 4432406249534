<template>
  <v-app-bar
    app
    color="teal"
    dark
    elevation="0"
  >
    {{auth.email}}
    <v-spacer></v-spacer>
    <v-btn
      @click="logout"
      elevation="0"
    >Deconnexion</v-btn>
  </v-app-bar>
</template>

<script>
export default {
methods: {
  logout() {
    this.$store.dispatch('logout')
  }
},
computed: {
  auth() {
    return this.$store.state.auth.auth || null
  }
}
}
</script>

<style>

</style>