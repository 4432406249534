<template>
  <v-app>
    <Loader v-if="loading" />
    <div v-else>
      <AppBar v-if="auth" />
      <v-main>
        <SignUpForm v-if="!auth" />
        <Info v-else-if="done" />
        <CreateProfile v-else />
        <!-- <router-view  /> -->
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Loader from './components/profile/helpers/Loader.vue'
import AppBar from './components/root/AppBar.vue'
import SignUpForm from './components/signUpForm.vue'
import CreateProfile from './views/Profile.vue'
import Info from './views/Info.vue'

export default {
  name: "App",
  data: () => ({}),
  computed: {
    auth() {
      return this.$store.state.auth.auth || null
    },
    loading() {
      return this.$store.state.loading
    },
    done() {
      return this.$store.state.done
    }
  },
  components: { AppBar, SignUpForm, Loader, CreateProfile, Info },
};
</script>