<template>
  <div>
    <div class="text-h6 mb-5 teal--text">
      Contacts
    </div>
    <div>
      <v-row>
        <v-col>
          <v-text-field
            color="teal"
            label="Nom"
            v-model="contacts.name"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            color="teal"
            label="Prénom"
            v-model="contacts.surname"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        color="teal"
        label="Numèro de téléphone"
        v-model="contacts.phone"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    contacts() {
      return this.$store.state.profile.manager
    }
  }
}
</script>

<style>

</style>