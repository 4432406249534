<template>
  <v-container>
    <v-card
      outlined
    >
      <v-card-text>
        <ProfileAirport />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <ProfileLegalData />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <ProfileRestaurant />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <ProfileContacts />
      </v-card-text>
      <v-card-actions>
        <VerifyAndSubmit />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import ProfileAirport from '../components/profile/ProfileAirport.vue';
import ProfileLegalData from '../components/profile/ProfileLegalData.vue';
import ProfileRestaurant from '../components/profile/ProfileRestaurant.vue';
import ProfileContacts from '../components/profile/ProfileContacts.vue';
import VerifyAndSubmit from '../components/profile/helpers/VerifyAndSubmit.vue';
export default {
  data: () => ({
    loading: false,
  }),
  computed: {
    auth() {
      return this.$store.state.auth.auth || null
    }
  },
  components: { ProfileAirport, ProfileLegalData, ProfileRestaurant, ProfileContacts, VerifyAndSubmit }
}
</script>

<style>

</style>