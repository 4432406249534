import { db } from "@/main.js"
import { collection, getDocs } from "firebase/firestore"

export default {
  state: {
    airports: null,
    selectedAirport: null,
  },
  mutations: {
    setAirports(s, payload) {
      s.airports = payload
    },
    clearAirports(s) {
      s.airports = null
    },
    setSelectedAirports(s, payload) {
      s.airport = payload
    },
    clearSelectedAirports(s) {
      s.airport = null
    }
  },
  actions: {
    async fetchAirports({commit, dispatch}) {
      let a = []
      const querySnapshot = await getDocs(collection(db, "airports"));
      querySnapshot.forEach((doc) => {
        a.push(doc.data())
      });
      commit('setAirports', a)
    },
    changeSelectedAirport({commit, dispatch}, data) {
      commit('setSelectedAirports', data)
    }
  },
  modules: {
  }
}
