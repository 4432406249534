<template>
  <div>
    <div class="text-h6 mb-5 teal--text">
      Restaurant
    </div>
    <div>
      <v-text-field
        color="teal"
        label="Nom"
        v-model="restaurant.name"
      ></v-text-field>
      <v-text-field
        color="teal"
        label="Address"
        v-model="restaurant.address"
        hint="Par rapport à l'aéroport. Ex: Zone Public / Salle d'emberquement A / etc."
      ></v-text-field>
    </div>
    <div>
      <div
        v-for="d in hours"
        :key="d"
      >
        <Hours :day="d" />
      </div>
    </div>
  </div>
</template>

<script>
import Hours from './helpers/Hours.vue';
export default {
    components: { Hours },
    computed: {
      hours() {
        return this.$store.state.profile.days
      },
      restaurant() {
        return this.$store.state.profile.restaurant
      }
    }
}
</script>

<style>

</style>