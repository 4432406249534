<template>
<div>
  <div class="text-h6 teal--text">
    {{week[day.day_of_week - 1]}}
    <v-switch
      v-model="day.open"
      :label="day.open ? 'Ouvert' : 'Fermé'"
      inset
      color="teal"
    ></v-switch>
  </div>
  <v-row dense justify="center" align="center">
    <v-col>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="day.hours[0]"
            label="Ouverture des livraisons"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            color="teal"
            v-bind="attrs"
            v-on="on"
            :disabled="!day.open"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu"
          v-model="day.hours[0]"
          format="24hr"
          color="teal"
          full-width
          @click:minute="$refs.menu.save(time)"
        ></v-time-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="day.hours[1]"
            label="Fermeture des livraisons"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            color="teal"
            :disabled="!day.open"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menu2"
          v-model="day.hours[1]"
          format="24hr"
          color="teal"
          full-width
          @click:minute="$refs.menu.save(time)"
        ></v-time-picker>
      </v-menu>
    </v-col>
  </v-row>
</div>
</template>

<script>
export default {
  data: () => ({
    openningTime: null,
    closingTime: null,
    time: null,
    menu: false,
    menu2: false,
    open: true,
    week: ["Lundi", "Mardi", "Mercredi", "Jedui", "Vendredi", "Samedi", "Dimanche"]
  }),
  props: {
    day: {}
  },
  // computed: {
  //   restaurant() {
  //     return this.$store.state.profile.days
  //   }
  // }
}
</script>
