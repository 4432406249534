<template>
  <v-container>
    <v-card
      outlined
    >
      <v-card-text>
        Votre demande est enregistrée, vous allez recevoir un mail de confirmation quand tout est pret.
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>