<template>
  <div>
    <div class="text-h6 mb-5 teal--text">
      Choisissez votre aéroport
    </div>
    <div v-if="airports">
      <v-radio-group v-model="airport">
        <v-radio
          v-for="n in airports"
          :key="n"
          color="teal"
          :label="n.name"
          :value="n"
        ></v-radio>
      </v-radio-group>
    </div>
    <div v-else>Loading...</div>
  </div>
</template>

<script>
export default {
  data: () => ({
    airport: null,
  }),
  created() {
    this.$store.dispatch('fetchAirports')
  },
  computed: {
    airports() {
      return this.$store.state.airports.airports
    },
    selectedAirport() {
      return this.$store.state.profile.airport
    }
  },
  watch: {
    airport() {
      this.$store.commit('setAirport', this.airport)
    }
  }
}
</script>

<style>

</style>