<template>
  <v-container fill-height>
    <v-card
      outlined
      width="500"
      class="pa-10 mx-auto"
      :disabled="loading"
      @keydown.enter="register"
    >
      <v-card-title class="teal--text">Créer un compte</v-card-title>
      <v-card-text>
        <v-alert
          type="error"
          dense
          v-show="error"
        >
          {{error}}
        </v-alert>
        <v-form v-model="valid">
          <v-text-field
            label="Email"
            color="teal"
            v-model.trim="email"
            :rules="emailRules"
            validate-on-blur
          ></v-text-field>
          <v-text-field
            label="Mot de passe"
            color="teal"
            type="password"
            v-model="password"
            :rules="passwordRules"
          ></v-text-field>
          <v-text-field
            label="Confirmez le mot de passe"
            color="teal"
            type="password"
            v-model="repassword"
            :rules="[(this.password === this.repassword) || 'Les mots de passe sont differents']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="teal"
          elevation="0"
          class="white--text"
          :loading="loading"
          @click="register"
          :disabled="!email || !password || !repassword || !valid"
        >Enregistrer</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
    loading: false,
    valid: false,
    email: null,
    password: null,
    repassword: null,
    emailRules: [
      v => !!v || "L'address mail n'est pas vilde",
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    passwordRules: [ 
      v => !!v || 'Le mot de passe est obligatoire', 
      v => (v && v.length >= 8) || 'Le mot de passe doit contenir 8 characheres ou plus',
      v => /(?=.*[A-Z])/.test(v) || 'Doit contenir au moins une lettre majiscule', 
      v => /(?=.*[a-z])/.test(v) || 'Doit contenir au moins une lettre miniscule', 
      v => /(?=.*\d)/.test(v) || 'Doit contenir au moins un chiffre', 
      v => /([-+!@$%])/.test(v) || 'Doit contenir au moins un charachtere special [-+!@#$%]' 
    ]
  }),
  methods: {
    async register() {
      this.loading = true
      const data = {
        email: this.email,
        password: this.password
      }
      await this.$store.dispatch('register', data)
      this.loading = false
    }
  },
  computed: {
    error() {
      return this.$store.state.auth.error
    }
  }
}
</script>
